<template>
	<div style="position: relative;">
		<div  class="vote_bar">
			<div class="row" v-if="(solicitud && !solicitud.historico) && !aVotado">
				<div class="col-sm-6 col-md-3 offset-sm-6 offset-md-9 text-right">
					<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/aprove-white.svg" title="Aprobar" @click="aprobar=true" />
					<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/cancel-white.svg" title="Cancelar" @click="rechazar=true" />
					<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/coments-white.svg" title="Comentarios" @click="obtener_comentarios" />
				</div>
			</div>
			<div class="row" v-else>
				<div class="col-sm-6 col-md-6 offset-sm-3 offset-md-6 text-right">
					<div class="row justify-content-end align-items-center">
						<h3>Histórico</h3>
						<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/coments-white.svg" title="Comentarios" @click="obtener_comentarios" />
					</div>
				</div>
			</div>
		</div>


		<botonesMobile/>

		<Modal v-if="aprobar" :options="modalConfig" @close="aprobar=false" class="bpb-modal">
			<h2 class="title">Comentarios</h2>
			<div class="body">
				<div class=" col-sm-12">
					<div class="generales-data row">
						<span class="heanding-mobile col">
						Valor Propiedad:
						</span>
						<span class="col text-left">
						${{formatPrice(parseFloat(solicitud.opinionValor.valor))}}
						</span>
					</div>
					<div class="generales-data row">
						<span class="heanding-mobile col">
						Ingresos:
						</span>
						<span class="col text-left">
						${{formatPrice(parseFloat(solicitud.Ocr.ingresosTotal))}}
						</span>
					</div>
					<div class="generales-data row">
						<span class="heanding-mobile col">
						Score:
						</span>
						<span class="col text-left">
						{{solicitud.opinionValor.score}}
						</span>
					</div>
				</div>
				<hr>
				<template v-if="$auth.can('comite','aprobar')">
				<div class="row voto_acreditador_cabecera">
					<div class="col-sm-3">Acreditador</div>
					<div class="col-sm-2">Monto</div>
					<div class="col-sm-2">Plazo</div>
					<div class="col-sm-2">Decisión</div>
					<div class="col-sm-3">Observación</div>
				</div>
				<div v-for="voto in solicitud.solicitud.votaciones" :key="voto.id" class="row voto_acreditador">
					<div class="col-sm-3">{{ voto.acreditador.nombre }}</div>
					<div class="col-sm-2">${{ $helper.moneyFormat(voto.autorizado) }}</div>
					<div class="col-sm-2">{{ voto.plazo }} años</div>
					<div class="col-sm-2">{{ voto.estado_id == 4 ? 'Aprobada' : 'Rechazada' }}</div>
					<div class="col-sm-3">{{ voto.observacion }}</div>
				</div>
				<hr>
				</template>
				<div class="row form-group">
					<div class="col-sm-4">
						<label for="monto">Nuevo monto</label>
						<IC v-model="monto" name="monto" id="monto" label="$" :formats="['moneyFormat']" />
					</div>
					<div class="col-sm-4">
						<label for="palzo_nuevo">Nuevo plazo</label>
						<select v-model="plazo_real" name="palzo_nuevo" id="palzo_nuevo" class="form-control">
							<option value="1">Plazo 1 años</option>
							<option value="2">Plazo 2 años</option>
							<option value="3">Plazo 3 años</option>
							<option value="4">Plazo 4 años</option>
							<option value="5">Plazo 5 años</option>
							<option value="6">Plazo 6 años</option>
							<option value="7">Plazo 7 años</option>
							<option value="8">Plazo 8 años</option>
							<option value="9">Plazo 9 años</option>
							<option value="10">Plazo 10 años</option>
						</select>
					</div>
					<div class="col-sm-4">
						<label for="aforo">Aforo</label>
						<IC v-model="calculoAforo" label_right=": 1" name="aforo" id="aforo" :readonly="true" />
					</div>
				</div>
				<div v-if="!agregar_comentario" class="form-group">
					
				</div>
				<div v-if="agregar_comentario" class="row">
					<div class="col-sm-12">
						<label for="comentario">Comentario</label>
						<textarea v-model="observacion" style="height: 140px!important;resize:none;" name="comentario" id="comentario" cols="30" rows="10" class="form-control" placeholder="Opcional"></textarea>
					</div>
				</div>
			</div>
			<div class="footer text-right">
				<button class="btn btn-success mr-2" @click="aprobar_solicitud">Votar</button>
				<button class="btn btn-primary" @click="agregar_comentario=!agregar_comentario">Agregar Comentario</button>
			</div>
		</Modal>

		<Modal v-if="rechazar" :options="modalConfig" @close="rechazar=false" class="bpb-modal">
			<h2 class="title">Comentarios</h2>
			<div class="body">
				<div class="row">
					<div class="col-sm-12">
						<label for="comentario">Comentario</label>
						<textarea v-model="observacion" style="height: 140px!important;resize:none;" name="comentario" id="comentario" cols="30" rows="10" class="form-control" placeholder="Opcional"></textarea>
					</div>
				</div>
			</div>
			<div class="footer">
				<button class="btn principal-btn col-sm-12" @click="rechazar_solicitud">Rechazar</button>
			</div>
		</Modal>

		<Modal v-if="vercomentarios" :options="modalConfig" @close="vercomentarios=false" class="bpb-modal">
			<h2 class="title">Comentarios</h2>
			<div class="body">
				<div v-for="voto of comentarios" class="row" :key="voto.id">
					<div class="col-sm-12">
            <h6>{{ voto.acreditador.nombre }} :</h6>
            <p> {{ voto.observacion }} </p>
            <p v-if="voto.autorizado"> ${{ formatPrice(voto.autorizado) }} </p>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'
	import comite from '@/apps/comite/api/comite'
	import botonesMobile from './BotonesMobile'
	import IC from '@/components/Form/InputCompuesto'

	import api from '@/apps/comite/api/comite'

	export default {
		components: {
			Modal, botonesMobile, IC
		}
		// ,props: {
		// 	solicitud: {
		// 		type: Object,
    //     default: () => {
    //       return {}
    //     }
		// 	}
		// }
		,data: () => ({
			solicitud: null,
			modalConfig: {
				width: '50vw'
				,close: true
			}

			,aprobar: false
			,rechazar: false
			,vercomentarios: false

			,agregar_comentario: false
			,monto: null
			,plazo: null
			,aforo: null
			,comentarios: []
			,observacion: null
			,aVotado: false
		})
		,mounted() {
			this.solicitud = this.$store.state.solicitud;
			// this.obtener_solicitud();

			this.$log.info('importe: ',this.solicitud.solicitud);
			this.monto = this.solicitud.solicitud.importe_solicitado ? this.solicitud.solicitud.importe_solicitado : this.solicitud.ServiciosOriginacion.importe_solicitado;
		}
		,methods: {
			aprobar_solicitud: function() {
				this.enviar(4);
			}
			,rechazar_solicitud: function() {
				this.enviar(5);
			}
			,enviar: function(estatus) {
                if(this.ceder_credito == false){
                    this.ceder_credito = 0
                }else if(this.ceder_credito == true){
                    this.ceder_credito = 1
                }
				let plazo = this.solicitud.solicitud.plazo > 10 ? (this.solicitud.solicitud.plazo/12) : this.solicitud.solicitud.plazo;

				comite.votacion({
		        	observacion: this.observacion,
		        	autorizado: parseFloat(this.monto),
							plazo: this.plazo ? this.plazo : plazo,
		        	tasa_interes: 0,
		        	aforo: this.aforo,
		        	comite_id: this.solicitud.solicitud.comite_id,
		        	solicitud_id: this.solicitud.solicitud.id,
		        	estado_id: estatus,
		        	producto: 'si',
		        	tipo_credito: "1",
              cesion_credito_organizacion_id: this.ceder_credito,
		        })
		        .then(res => {
		        	this.$log.info('res', res);
		        	this.aprobar = false;
		        	this.rechazar = false;
		        	this.observacion = null;
							let solicitud_id = this.solicitud.solicitud.id;
							this.solicitud.solicitud.id = 0;
							this.$store.commit('set', { solicitud: null });

							if (this.$route.name == 'comite_resumen_modelo')
								return this.$emit('update');

							this.$router.push({name: 'comite_resumen_modelo', params: {id: solicitud_id}});
		        })
		        .catch(err => {
		        	this.$log.info('err', err);
		        	this.$helper.showAxiosError(err,'Error');
		        })
			}
			,obtener_comentarios: function() {
				if (!this.solicitud.historico) {
					comite.getVotacion(this.solicitud.solicitud.id)
					.then(res => {
						this.$log.info('res', res);
						this.comentarios = res.data;
						this.vercomentarios = true;
					})
					.catch(err => {
						this.$log.info('err', err.response);
						this.$notify({
							group: 'alert'
							,type: 'error'
							,title: 'Error'
							,text: (err.response.status == 404 ? 'No hay comentarios para esta solicitud' : err.response.data.message)
							,duration: 5000
						})
					})
				}else {
					comite.obtener_comentarios(this.solicitud.id)
					.then(res => {
						this.$log.info('res', res);
						this.comentarios = res.data;
						this.vercomentarios = true;
					})
					.catch(err => {
						this.$log.info('err', err.response);
						this.$notify({
							group: 'alert'
							,type: 'error'
							,title: 'Error'
							,text: (err.response.status == 404 ? 'No hay comentarios para esta solicitud' : err.response.data.message)
							,duration: 5000
						})
					})
				}
			}
			,formatPrice(value) {      
			    let val = (parseFloat(value)/1).toFixed(0).replace(',', '.')
			    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    		}
		}
		,computed: {
			calculoAforo: {
				get() {
					if (this.solicitud)
						return (parseFloat(this.solicitud.opinionValor.valor)/parseFloat(this.monto)).toFixed(1);
					else
						return 0.0;
				}
				,set(value) {
					this.aforo = value;
				}
			}
			,plazo_real: {
				get() {
					if (!this.plazo) {
						let plazo = this.plazo;
						plazo = parseInt(this.solicitud.solicitud.plazo);

						if (plazo > 10)
							plazo = plazo / 12;
						
						this.plazo = plazo;
					}

					return this.plazo;
				}
				,set(val) {
					this.plazo = parseInt(val);
				}
			}
			,isVoted() {
				let aVotado = false;

				this.$log.info('id', this.$auth.getUser().id);
				this.$log.info('solicitud', this.solicitud);

				if (!this.solicitud)
					return aVotado;
				
				this.$log.info('verificamos los votos');

				let solicitud = this.solicitud.solicitud;

				if (solicitud.votacion && solicitud.votaciones.length > 0) {
					this.$log.info('hay votos');
					for(let i=0; i<solicitud.votaciones.length; i++) {
						if (solicitud.votaciones[i].acreditador_id == this.$auth.getUser().id) {
							this.$log.info('ya vote');
							aVotado = true;
							i=solicitud.votaciones.length;
						}
					}
				}

				return aVotado;
			}
		},
		watch: {
			solicitud(val) {
				if (val) {
					let aVotado = false;

					this.$log.info('id', this.$auth.getUser().id);
					this.$log.info('solicitud', this.solicitud);

					if (!this.solicitud)
						return aVotado;
					
					this.$log.info('verificamos los votos');

					let solicitud = val.solicitud;
					this.$log.info('verificamos los votos', solicitud.votaciones.length);

					if (solicitud.votaciones && solicitud.votaciones.length > 0) {
						this.$log.info('hay votos');
						for(let i=0; i<solicitud.votaciones.length; i++) {
							if (solicitud.votaciones[i].acreditador_id == this.$auth.getUser().id) {
								this.$log.info('ya vote');
								aVotado = true;
								i=solicitud.votaciones.length;
							}
						}
					}

					this.aVotado = aVotado;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.vote_bar {
		width: 100%;
		background-color: #e6e6e6!important;
		margin: 0px auto;
		padding: 0px 15px;
		// overflow: auto;
		position: fixed;
		bottom: 0;
		left: 0;
		
		img {
			width: 55px;
			margin: 20px 15px;
		}
		
		h3 {
			margin-top: 10px;
		}
	}

	.voto_acreditador_cabecera {
		background-color: #5e5e5e;
		color: #fff;

		div {
			padding: 5px 10px;
		}
	}

	.voto_acreditador {
		border-bottom: solid 1px #bbbbbb;
		div {
			padding: 5px 10px;
		}
	}

	.voto_acreditador:nth-child(2n+0) {
		background-color: #eeeeee;
	}

	@media (max-width: 768px) {
		.vote_bar {
			bottom: 60px !important;

			img {
				width: 30px;
				margin: 10px 15px;
			}
		}
	} 
</style>